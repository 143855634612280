import { useMutation } from 'react-query'

import {
	loadAssetInlineCollection,
	loadOrganiationList,
	loadAwsBucketName,
	loadUsersOnSearch,
	loadAssetAuthToken,
	addTimeSheetData,
	resetPassword,
	loadChatList,
	initiateSwipe,
	loadChatCount,
	loadMonthlyGScore,
	loadMonthlyWorkflowList,
	loadOverallGScore,
	loadLeaveMappings,
	loadBotBetaMapping,
	loadLanguageList,
} from '../services/asset.service'
import { getLocalAuthDetails } from '../utils/common.util'
import { getCurrentDateUTC, getMonth, getYear } from '../utils/date.utils'

export function useLoadOrganizationList() {
	return useMutation(payload => loadOrganiationList(payload))
}

export function useLoadAssetInlineCollection() {
	return useMutation(async (reqPayload = {}) => {
		const {
			organization_id,
			account_id,
			workforce_id,
			asset_id,
			asset_token_auth = '',
			asset_image_path = '',
		} = reqPayload

		return loadAssetInlineCollection({
			organization_id,
			account_id,
			workforce_id,
			asset_id,
			asset_image_path,
			asset_token_auth,
			app_version: 1.0,
			device_token_id: 5,
		})
	})
}

export function useLoadAwsBucketName() {
	return useMutation(() => {
		return loadAwsBucketName({
			...getLocalAuthDetails(),
			bucket_month: getMonth(new Date()),
			bucket_year: getYear(new Date()),
			app_version: 1.0,
			device_token_id: 5,
		})
	})
}

export function useLoadUsersOnSearch() {
	return useMutation(async params => {
		let payload = {
			...getLocalAuthDetails(),
			...params.params,
			account_id: 0,
			workforce_id: 0,
		}

		let res = await loadUsersOnSearch(payload)
		if (res.data.status === 200) {
			let employeeList = []
			if (res.data.response && res.data.response.length > 0) {
				employeeList = res.data.response.filter(
					emp => !!emp.operating_asset_first_name
				)
				return employeeList
			} else {
				return []
			}
		} else {
			return []
		}
	})
}

export function useLoadAssetAuthToken() {
	return useMutation(async ({ userData }) => {
		const {
			organization_id,
			account_id,
			workforce_id,
			asset_id,
			operating_asset_id,
			asset_image_path,
		} = userData

		let payload = {
			organization_id,
			account_id,
			workforce_id,
			asset_id,
			operating_asset_id,
			asset_image_path,
			asset_token_push: '',
			asset_hardware_imei: '',
			asset_hardware_manufacturer: '',
			asset_hardware_model: '',
			asset_hardware_os_id: '',
			asset_hardware_os_version: '',
			message_unique_id: '',
			track_latitude: '0.0',
			track_longitude: '0.0',
			track_altitude: 0,
			track_gps_accuracy: '0',
			track_gps_datetime: getCurrentDateUTC(),
			track_gps_status: 0,
			track_gps_location: 0,
			service_version: 1.0,
			app_version: 1.0,
			device_os_id: 5,
			flag_dev: '',
			flag_app_account: '',
			flag_is_linkup: '',
			timezone_offset: '',
		}
		const res = await loadAssetAuthToken(payload)
		const enc_token = res.data.response.enc_token
		return { ...userData, asset_encryption_token_id: enc_token }
	})
}

export function useAddTimeSheetData() {
	const userDetails = getLocalAuthDetails()
	return useMutation(params => {
		return addTimeSheetData({
			...params,
			...getLocalAuthDetails(),
			bucket_month: getMonth(new Date()),
			bucket_year: getYear(new Date()),
			app_version: 1.0,
			device_token_id: 5,
			operating_asset_id: userDetails.asset_id,
		})
	})
}

export function useSetPassword() {
	return useMutation(async (params = {}) => {
		try {
			let payload = {
				...params,
				...getLocalAuthDetails(),
			}
			let res = await resetPassword(payload)
			return res
		} catch (error) {
			return error
		}
	})
}

export function LoadChatData() {
	return useMutation(async (params = {}) => {
		try {
			let payload = {
				...params,
				...getLocalAuthDetails(),
			}
			let res = await loadChatList(payload)
			return res.data.response
		} catch (error) {
			return error
		}
	})
}

export function useSwipeChatInitiate() {
	return useMutation(async (params = {}) => {
		try {
			let payload = {
				...params,
				...getLocalAuthDetails(),
			}
			let res = await initiateSwipe(payload)
			return res.data.response
		} catch (error) {
			return error
		}
	})
}

export function useLoadChatCount() {
	return useMutation(async (params = {}) => {
		try {
			let payload = {
				...params,
				...getLocalAuthDetails(),
			}
			let res = await loadChatCount(payload)
			return res.data.response
		} catch (error) {
			return error
		}
	})
}

export function useAssetMonthlyGScore() {
	return useMutation(async (params = {}) => {
		try {
			let payload = {
				...params,
				...getLocalAuthDetails(),
			}
			let res = await loadMonthlyGScore(payload)
			return res.data.response
		} catch (error) {
			return error
		}
	})
}

export function useAssetMonthlyWorkflowList() {
	return useMutation(async (params = {}) => {
		try {
			let payload = {
				...params,
				...getLocalAuthDetails(),
			}
			let res = await loadMonthlyWorkflowList(payload)
			return res.data.response
		} catch (error) {
			return error
		}
	})
}

export function useAssetOverallGScore() {
	return useMutation(async (params = {}) => {
		try {
			let payload = {
				...params,
				...getLocalAuthDetails(),
			}
			let res = await loadOverallGScore(payload)
			return res.data.response
		} catch (error) {
			return error
		}
	})
}

export function useLoadLeaveMapping() {
	return useMutation(async (params = {}) => {
		try {
			let payload = {
				...params,
				...getLocalAuthDetails(),
			}
			let res = await loadLeaveMappings(payload)
			return res.data.response
		} catch (error) {
			return error
		}
	})
}

export function useLoadBotBetaAssetMapping() {
	return useMutation(async (params = {}) => {
		const localAuthDetails = getLocalAuthDetails()
		try {
			let payload = {
				...params,
				...localAuthDetails,
			}
			let res = await loadBotBetaMapping(payload)
			return res.data.response
		} catch (error) {
			return error
		}
	})
}

export function useLoadLanguageList() {
	return useMutation(async (params = {}) => {
		const localAuthDetails = getLocalAuthDetails()
		try {
			let payload = {
				...params,
				...localAuthDetails,
			}
			let res = await loadLanguageList(payload)
			return res.data.response
		} catch (error) {
			return error
		}
	})
}
