/* eslint-disable no-self-assign */
/* eslint-disable array-callback-return */
import { useMutation } from 'react-query'
import {
	activityBulkSummary,
	emailBulkSummary,
	addActivity,
	addGuests,
	addTimelineUpdate,
	alterDraft,
	alterFileCover,
	archiveDraft,
	exportTimeLine,
	inviteParticipant,
	loadActivityDetails,
	loadActivityTypeDetails,
	loadActivityListV1,
	loadActivityListV2,
	loadActivityParticipant,
	loadActivityStatusList,
	loadActivityTypeList,
	loadChildOrderActivityList,
	loadEDCFieldId,
	loadFilterActivityList,
	loadFocusActivityList,
	loadFormFieldsList,
	loadReferenceFilters,
	loadReferenceList,
	loadReferenceListByTagType,
	LoadStatusAndActivityType,
	loadTimelineList,
	loadUnassignedActivityList,
	loadWorkforceLevelForms,
	removeParticipant,
	searchOrgContacts,
	setParticipantAsOwner,
	setParticipantAsLead,
	submitAsDraft,
	bulkEditForm,
	meetingEnable,
	resetPassword,
	resetChatCount,
	chatMsgList,
	addNewMsg,
	inviteChatParticipant,
	getOrgConfigList,
	getAccessSelectActivity,
	addParentActivity,
	accountTitleDupCheck,
	getGanntChildActivity,
	setActivityReference,
	updateActivityReference,
	deleteActivityReference,
	updateActivityDueDates,
	updateActivityProgress,
	alterActivityStatus,
	loadSelectCategory,
	checkActivityManager,
	setPriority,
	updateActivityMasterData,
	notificationAsset,
	updateCancelRequest,
	declineHistory,
	removeParticipantAsLead,
	loadWorkflowParticipant,
	timelineFilterOptions,
	loadUnassignedQueueList,
	editfieldConfigs,
	loadReferredActivity,
	loadActivityReceiverEmailId,
	loadGetFormRateLimit,
	loadDomainList,
	loadEmailThredList,
	loadEmailThredMap,
	loadActivityListMeeting,
} from '../services/activity.service'
import { loadSubmittedFormList } from '../services/form.service'
import { buildParticipantParams } from '../utils/activity.utils'
import { getLocalAuthDetails, getMessageUniqueId } from '../utils/common.util'
import {
	getAddToCurrentDateUTC,
	getCurrentDateUTC,
	getCurrentDateUTCSTART,
} from '../utils/date.utils'
import { buildFormData } from '../utils/form.util'
import { fetchManagerResources } from './../services/dashboard.service'
import { loadPreviousStatusList } from './../services/activity.service'

export function handleResponse(res, { page_start, page_limit: limit }) {
	if (res.status === 200) {
		if (res.data.response && res.data.response.length > 0) {
			let isMore = res.data.response.length === limit
			return {
				isMore,
				list: res.data.response,
				page_start: isMore ? res.data.response.length + page_start : page_start,
			}
		} else {
			return {
				isMore: false,
				list: [],
				page_start: page_start,
			}
		}
	} else {
		throw new Error('Something went wrong!')
	}
}

/**
 * // MYQUEUE, DELAYED, MENTIONS
 */

export function useLoadActivityListV1() {
	return useMutation(async (params = {}) => {
		try {
			let payload = {
				...params,
				...getLocalAuthDetails(),
				start_from: params.page_start,
				limit_value: params.page_limit,
				service_version: 1.0,
				app_version: 1.0,
				device_os_id: 5,
			}
			payload['target_asset_id'] = payload.asset_id
			let res = await loadActivityListV1(payload)
			return handleResponse(res, {
				page_start: payload.page_start,
				page_limit: payload.page_limit,
			})
		} catch (error) {
			return error
		}
	})
}
export function useLoadActivityMeetingList() {
	return useMutation(async (params = {}) => {
		try {
			let payload = {
				...params,
				...getLocalAuthDetails(),
				start_from: params.page_start,
				limit_value: params.page_limit,
				service_version: 1.0,
				app_version: 1.0,
				device_os_id: 5,
			}
			payload['target_asset_id'] = payload.asset_id
			let res = await loadActivityListMeeting(payload)
			return handleResponse(res, {
				page_start: payload.page_start,
				page_limit: payload.page_limit,
			})
		} catch (error) {
			return error
		}
	})
}

/**
 * Handle MyFocus List
 * @param {page_start, page_limit}
 */
export function useLoadFocusActivityList() {
	return useMutation(async (params = {}) => {
		try {
			let payload = {
				...params,
				...getLocalAuthDetails(),
				start_from: params.page_start,
				limit_value: params.page_limit,
				service_version: 1.0,
				app_version: 1.0,
				device_os_id: 5,
			}
			let res = await loadFocusActivityList(payload)
			return handleResponse(res, {
				page_start: payload.page_start,
				page_limit: payload.page_limit,
			})
		} catch (error) {
			return error
		}
	})
}
/**
 * Handle Unassigned Queue List
 */
export function useLoadUnassignedActivityList() {
	return useMutation(async (params = {}) => {
		try {
			let payload = {
				...params,
				...getLocalAuthDetails(),
				start_from: params.page_start,
				limit_value: params.page_limit,
				service_version: 1.0,
				app_version: 1.0,
				device_os_id: 5,
			}
			payload['target_asset_id'] = payload.asset_id
			let res = await loadUnassignedActivityList(payload)
			return handleResponse(res, {
				page_start: payload.page_start,
				page_limit: payload.page_limit,
			})
		} catch (error) {
			return error
		}
	})
}

/**
 * QUEUE SPECIFIC ACITVITY LIST
 */
export function useLoadActivityListV2() {
	return useMutation(async (params = {}) => {
		try {
			let payload = {
				...params,
				...getLocalAuthDetails(),
				start_from: params.page_start,
				limit_value: params.page_limit,
				service_version: 1.0,
				app_version: 1.0,
				device_os_id: 5,
			}
			payload['target_asset_id'] = payload.asset_id
			let res = await loadActivityListV2(payload)
			return handleResponse(res, {
				page_start: payload.page_start,
				page_limit: payload.page_limit,
			})
		} catch (error) {
			return error
		}
	})
}
export function useLoadUnassignedQueueList() {
	return useMutation(async (params = {}) => {
		try {
			let payload = {
				...params,
				...getLocalAuthDetails(),
				start_from: params.page_start,
				limit_value: params.page_limit,
				service_version: 1.0,
				app_version: 1.0,
				device_os_id: 5,
			}
			payload['target_asset_id'] = payload.asset_id
			let res = await loadUnassignedQueueList(payload)
			return handleResponse(res, {
				page_start: payload.page_start,
				page_limit: payload.page_limit,
			})
		} catch (error) {
			return error
		}
	})
}

/**
 * QUEUE SPECIFIC ACITVITY LIST
 */
export function useLoadFilterActivityList() {
	return useMutation(async (params = {}) => {
		try {
			let payload = {
				...getLocalAuthDetails(),
				...params,
				start_from: params.page_start,
				limit_value: params.page_limit,
				service_version: 1.0,
				app_version: 1.0,
				device_os_id: 5,
			}
			payload['target_asset_id'] = payload.asset_id
			let res = await loadFilterActivityList(payload)
			return handleResponse(res, {
				page_start: payload.page_start,
				page_limit: payload.page_limit,
			})
		} catch (error) {
			return error
		}
	})
}

/**
 * Load timeline of an activity
 *
 */

export function useLoadTimelineList() {
	return useMutation(async params => {
		try {
			let user = getLocalAuthDetails()
			let payload = {
				...user,
				auth_asset_id: user.asset_id,
				...params,
				start_from: params.page_start,
				limit_value: params.page_limit,
				service_version: 1.0,
				app_version: 1.0,
				device_os_id: 5,
			}
			let res = await loadTimelineList(payload)
			res.data.response = res.data.response?.data
			return handleResponse(res, {
				page_start: payload.page_start,
				page_limit: payload.page_limit,
			})
		} catch (error) {
			return error
		}
	})
}

export function useAddTimelineUpdate() {
	return useMutation(async params => {
		try {
			const {
				isDesignate,
				bulk_summary,
				activity_stream_type_id,
				timeline_stream_type_id,
			} = params
			let user = getLocalAuthDetails()
			let payload = {
				...user,
				...params,
				activity_access_role_id: 27,
				activity_channel_id: 0,
				activity_channel_category_id: 0,
				activity_sub_type_id: -1,
				activity_stream_type_id: !!activity_stream_type_id
					? activity_stream_type_id
					: !!isDesignate
					? isDesignate === 2
						? 325
						: 711
					: !!bulk_summary
					? 509
					: 325,
				activity_timeline_text: '',
				activity_timeline_url: '',
				asset_message_counter: 0,
				app_version: 1.0,
				auth_asset_id: user.asset_id,
				datetime_log: getCurrentDateUTC(),
				device_os_id: 5,
				flag_pin: 0,
				flag_priority: 0,
				flag_offline: 0,
				flag_retry: 0,
				message_unique_id: getMessageUniqueId(user.asset_id),
				service_version: 1.0,
				timeline_transaction_id: getMessageUniqueId(user.asset_id),
				timeline_stream_type_id: !!timeline_stream_type_id
					? timeline_stream_type_id
					: !!isDesignate
					? isDesignate === 2
						? 325
						: 711
					: !!bulk_summary
					? 509
					: 325,
				track_latitude: '0.0',
				track_longitude: '0.0',
				track_altitude: 0,
				track_gps_accuracy: '0',
				track_gps_status: 0,
				track_gps_datetime: getCurrentDateUTC(),
			}
			return await addTimelineUpdate(payload)
		} catch (error) {
			return error
		}
	})
}

/**
 * Participant View
 *  - load activity participants
 *  - load organization contacts
 *  - invite person to activity
 *  - remove participant from activity
 *  - set participant as owner
 */

export function useLoadActivityParticipant() {
	return useMutation(async params => {
		try {
			let payload = {
				...getLocalAuthDetails(),
				...params,
				app_version: 1,
				service_version: 1,
				device_os_id: 5,
				page_limit: 100, /// TODO: Handle recursive calls if needed.
				page_start: 0,
				datetime_differential: '1970-01-01 00:00:00',
			}
			let res = await loadActivityParticipant(payload)
			res.data.response = res.data.response?.data
			return handleResponse(res, {
				page_start: payload.page_start,
				page_limit: payload.page_limit,
			})
		} catch (error) {
			return error
		}
	})
}
export function useLoadDomainList() {
	return useMutation(async params => {
		try {
			let payload = {
				...getLocalAuthDetails(),
				...params,
				app_version: 1,
				service_version: 1,
				device_os_id: 5,
				limit_value: 100, /// TODO: Handle recursive calls if needed.
				start_from: 0,
			}
			let res = await loadDomainList(payload)
			res.data.response = res.data.response
			return handleResponse(res, {
				page_start: payload.page_start,
				page_limit: payload.page_limit,
			})
		} catch (error) {
			return error
		}
	})
}
export function useLoadEmailThreadList() {
	return useMutation(async params => {
		try {
			let payload = {
				...getLocalAuthDetails(),
				...params,
				app_version: 1,
				service_version: 1,
				device_os_id: 5,
				limit_value: 100, /// TODO: Handle recursive calls if needed.
				start_from: 0,
			}
			let res = await loadEmailThredList(payload)
			res.data.response = res.data.response
			return handleResponse(res, {
				page_start: payload.page_start,
				page_limit: payload.page_limit,
			})
		} catch (error) {
			return error
		}
	})
}
export function useLoadEmailThreadmaping() {
	return useMutation(async params => {
		try {
			let payload = {
				...getLocalAuthDetails(),
				...params,
				app_version: 1,
				service_version: 1,
				device_os_id: 5,
				limit_value: 100, /// TODO: Handle recursive calls if needed.
				start_from: 0,
			}
			let res = await loadEmailThredMap(payload)
			res.data.response = res.data.response
			return handleResponse(res, {
				page_start: payload.page_start,
				page_limit: payload.page_limit,
			})
		} catch (error) {
			return error
		}
	})
}
/**
 * As per the previous changes seem timeline
 * entry has been handled already.
 * @param required /// target_asset_id: participant.asset_id,
 *  owner_flag:  1 - set : 0 - reset,
 *  content :
 *    - 0 : Name revoke ownership  Name @ (time)
 *    - 1 : Name set Name as owner @ (time)
 */
export function useSetParticipantAsOwner() {
	return useMutation(async ({ owner_flag, content, ...rest }) => {
		try {
			let payload = {
				...getLocalAuthDetails(),
				owner_flag,
				content,
				...rest,
				mail_body: content,
				subject: content,
				timeline_stream_type_id: 711,
				app_version: 1,
				service_version: 1,
				device_os_id: 5,
			}
			return await setParticipantAsOwner(payload)
		} catch (error) {
			return error
		}
	})
}

/**
 *
 * @params
 *  {
 *    activity_id,
 *    lead_asset_id,   
 *      {organization_id,
          account_id,
          workforce_id,
          asset_type_id,
          asset_category_id,
          asset_id,
          access_role_id,
          message_unique_id}
 *  }
 *
 *
 *
 */
export function useSetParticipantAsLead() {
	return useMutation(async ({ lead_asset_id, ...rest }) => {
		try {
			let payload = {
				...getLocalAuthDetails(),
				lead_asset_id,
				...rest,
				timeline_stream_type_id: 711,
				app_version: 1,
				service_version: 1,
				device_os_id: 5,
			}
			return await setParticipantAsLead(payload)
		} catch (error) {
			return error
		}
	})
}

/**
 *
 * @params
 *  {
 *    activity_id,
 *    activity_participant_collection: string of array
 *      {organization_id,
          account_id,
          workforce_id,
          asset_type_id,
          asset_category_id,
          asset_id,
          access_role_id,
          message_unique_id}
 *  }
 *
 *
 *
 */
export function useRemoveParticipant() {
	return useMutation(async params => {
		try {
			let payload = {
				...getLocalAuthDetails(),
				...params,
				message_unique_id: getMessageUniqueId(),
				asset_message_counter: 0,
				flag_offline: 0,
				flag_retry: 0,
				track_latitude: 0.0,
				track_longitude: 0.0,
				track_gps_location: '',
				track_gps_datetime: getCurrentDateUTC(),
				track_gps_accuracy: 0.0,
				track_gps_status: 1,
				device_os_id: 5,
				service_version: 1.0,
				app_version: 1.0,
				api_version: 1.0,
			}
			return await removeParticipant(payload)
		} catch (error) {
			return error
		}
	})
}

/**
 *
 * @params :
 * activity_id,
 * activity_type_category_id: activityCategoryTypeId,
 * activity_type_id: activity.activity_type_id,
 * contact: {asset_type_id, asset_id,asset_first_name ,operating_asset_first_name,workforce_name}
 */

export function useInviteParticipant() {
	return useMutation(async ({ content, contact, participants, ...rest }) => {
		try {
			let user = getLocalAuthDetails()
			const { organization_id, account_id, workforce_id } = user
			const timelinePayload = {
				content: content,
				subject: content,
				mail_body: content,
				attachments: [],
				participant_added: content,
				activity_reference: [
					{
						activity_title: '',
						activity_id: '',
					},
				],
				asset_reference: [{}],
				form_approval_field_reference: [],
			}

			let participantInfo = []
			if (!!participants) {
				participantInfo = participants
			} else {
				participantInfo = [
					{
						organization_id,
						account_id,
						workforce_id,
						...contact,
						asset_category_id: 1,
						access_role_id: 1,
						message_unique_id: getMessageUniqueId(user.asset_id),
					},
				]
			}
			let payload = {
				...user,
				...rest,
				activity_participant_collection: JSON.stringify(participantInfo),
				activity_timeline_collection: JSON.stringify(timelinePayload),
				flag_pin: 0,
				flag_offline: 0,
				flag_retry: 0,
				message_unique_id: getMessageUniqueId(user.asset_id),
				asset_message_counter: 0,
				track_latitude: '0.0',
				track_longitude: '0.0',
				track_altitude: 0,
				track_gps_datetime: getCurrentDateUTC(),
				track_gps_accuracy: '0',
				track_gps_status: 0,
				service_version: 1.0,
				app_version: 1.0,
				api_version: 1.0,
				device_os_id: 5,
			}
			return await inviteParticipant(payload)
		} catch (error) {
			return error
		}
	})
}

export function useSearchOrgContacts() {
	return useMutation(async ({ search_string }) => {
		try {
			let payload = {
				...getLocalAuthDetails(),
				search_string,
				is_search: 1,
				is_sort: 0,
				is_status: 0,
				limit_value: 10,
				start_from: 0,
				activity_type_category_id: 5,
			}
			let res = await searchOrgContacts(payload)
			return res.data.response.map(u => {
				let data = JSON.parse(u.activity_inline_data)
				return {
					...u,
					...data,
				}
			})
		} catch (error) {
			return error
		}
	})
}

/**
 * On load reference files curresponding to each activity
 * Type: Internal / External
 */

export function useLoadReferenceList() {
	try {
		return useMutation(async params => {
			const payload = {
				...getLocalAuthDetails(),
				...params,
				device_os_id: 5,
			}
			let res
			if (!params.tag_type_id) {
				res = await loadReferenceList(payload)
			} else {
				res = await loadReferenceListByTagType(payload)
			}
			return handleResponse(res, {
				page_start: payload.page_start,
				page_limit: payload.page_limit,
			})
		})
	} catch (error) {
		return error
	}
}
/**
 * Load reference filters
 */
export function useLoadReferenceFilters() {
	return useMutation(async params => {
		try {
			let payload = {
				...getLocalAuthDetails(),
				...params,
				activity_type_category_id: 0,
				activity_type_id: 0,
				flag: 3,
				page_start: 0,
				page_limit: 50,
				device_os_id: 5,
				tag_id: 0,
				tag_type_id: 0,
			}
			let res = await loadReferenceFilters(payload)
			if (res.data && res.data.response) {
				return res.data.response
			}
		} catch (error) {
			return error
		}
	})
}

export function useLoadActivityDetails() {
	return useMutation(async params => {
		try {
			const payload = {
				...getLocalAuthDetails(),
				...params,
				device_os_id: 5,
			}
			let res = await loadActivityDetails(payload)
			if (res.status === 200 && res.data.response.length > 0) {
				return res.data.response[0]
			} else {
				return false
			}
		} catch (error) {
			return error
		}
	})
}

export function useGetFormRateLimit() {
	return useMutation(async params => {
		try {
			const payload = {
				...params,
			}
			let res = await loadGetFormRateLimit(payload)
			if (res.status === 200 && res.data.response.length > 0) {
				return res.data.response[0]
			} else {
				return false
			}
		} catch (error) {
			return error
		}
	})
}
export function useLoadActivityReceiverEmailId() {
	return useMutation(async params => {
		try {
			const payload = {
				...params,
			}
			let res = await loadActivityReceiverEmailId(payload)
			console.log(res, 'ress')
			if (res.status === 200 && res.data.response.length > 0) {
				return res.data.response
			} else {
				return false
			}
		} catch (error) {
			return error
		}
	})
}

export function useLoadActivityTypeDetails() {
	return useMutation(async params => {
		try {
			const payload = {
				...getLocalAuthDetails(),
				...params,
				device_os_id: 5,
			}
			let res = await loadActivityTypeDetails(payload)
			if (res.status === 200 && res.data.response.length > 0) {
				return res.data.response[0]
			} else {
				return false
			}
		} catch (error) {
			return error
		}
	})
}

export function useLoadFormFieldsList() {
	return useMutation(async params => {
		try {
			const payload = {
				...params,
				...getLocalAuthDetails(),
				device_os_id: 5,
			}
			let res = await loadFormFieldsList(payload)
			if (res.status === 200 && res.data.response.data.length > 0) {
				if (res.data.response.hasOwnProperty('data')) {
					const formFieldData = res.data.response.data
					let tempArray = {}
					formFieldData.map((field, index) => {
						let fieldValue = ''
						let isSmartForm = false
						if (index === 0) {
							if (field.next_field_id > 0) {
								// if first field having next field set more than 0 then it's smart form
								isSmartForm = true
							}
						} else if (field.data_type_combo_id > 0) {
							if (field.next_field_id > 0) {
								// if first field having next field set more than 0 then it's smart form
								isSmartForm = true
							}
						}
						if (field.data_type_combo_id > 0) {
							let optionsJson = {
								name: field.data_type_combo_value,
								value: field.data_type_combo_value, //field.data_type_combo_id
								comboId: field.data_type_combo_id,
								comboNextFieldId: field.next_field_id,
							}

							tempArray[field.field_id + 'i'] = {
								label: field.field_name,
								elementCatType: field.data_type_category_id,
								elementType: field.data_type_id,
								elementTypeName: field.data_type_name,
								elementConfig: {
									options: [optionsJson],
								},
								//value: '',
								value: optionsJson.value,
								validation: {
									required: !!field.field_mandatory_enabled,
									rules: field.field_inline_data,
								},
								valid: false,
								touched: false,
								validationEnabled: field.field_validation_enabled,
								fileUploadData: '',
								fieldSequenceId: field.field_sequence_id,
								nextFieldId: field.next_field_id + 'i',
							}
						} else {
							tempArray[field.field_id + 'i'] = {
								label: field.field_name,
								elementCatType: field.data_type_category_id,
								elementType: field.data_type_id,
								elementTypeName: field.data_type_name,
								elementConfig: {
									placeholder: 'Enter ' + field.field_name,
								},
								value: fieldValue,
								validation: {
									required: !!field.field_mandatory_enabled,
									rules: field.field_inline_data,
								},
								valid: false,
								touched: false,
								validationEnabled: field.field_validation_enabled,
								fileUploadData: '',
								fieldSequenceId: field.field_sequence_id,
								nextFieldId: field.next_field_id + 'i',
							}
						}
					})
					let formElementsArray = []
					for (let key in tempArray) {
						formElementsArray.push({
							id: key.slice(0, -1),
							config: tempArray[key],
						})
					}
					return formElementsArray
				}
			} else {
				return false
			}
		} catch (error) {
			return error
		}
	})
}

export function useCreateActivity() {
	return useMutation(async params => {
		try {
			const payload = {
				...params,
				...getLocalAuthDetails(),
				activity_type_category_id: 9,
				activity_sub_type_id: 0,
				activity_access_role_id: 21,
				asset_message_counter: 0,
				activity_status_type_category_id: 1,
				activity_status_type_id: 22,
				asset_participant_access_id: 21,
				activity_flag_file_enabled: 1,
				activity_parent_id: 0,
				flag_pin: 0,
				flag_offline: 0,
				flag_retry: 0,
				message_unique_id: getMessageUniqueId(),
				track_gps_datetime: getCurrentDateUTC(),
				track_latitude: '0.0',
				track_longitude: '0.0',
				track_altitude: 0,
				track_gps_accuracy: '0',
				track_gps_status: 0,
				service_version: 1,
				app_version: 1,
				api_version: 1,
				device_os_id: 5,
				activity_stream_type_id: 705,
				form_transaction_id: 0,
				activity_timeline_text: '',
				activity_timeline_url: '',
				flag_timeline_entry: 1,
				file_activity_id: 0,
				web_version: '1.0.1',
				is_mytony: 1,
				// is_refill: 0,
				expression: '',
				activity_timeline_collection: '',
			}
			let res = await addActivity(payload)
			if (res.status === 200) {
				let workflowParams = Object.assign({}, payload)
				workflowParams.activity_id = res.data.response.activity_id
				workflowParams.activity_type_category_id =
					workflowParams.activity_type_category_id || 48
				workflowParams.form_transaction_id =
					res.data.response.form_transaction_id || 0
				if (!!res.data.response.form_transaction_id) {
					workflowParams.activity_type_category_id =
						payload.form_api_activity_type_category_id || 48 // added new
					payload.activity_id = res.data.response.activity_id
					workflowParams.activity_type_id =
						payload.form_api_activity_type_id ||
						payload.form_workflow_activity_type_id
					console.log('workflowpara,', workflowParams)
					const finalRes = await addActivity(workflowParams)
					payload.workflow_activity_id = finalRes.data.response.activity_id
					payload.activity_id =
						finalRes.data.response.activity_id || payload.activity_id
					payload.data_activity_id = payload.activity_id
					payload.activity_type_category_id = 48
					payload.form_transaction_id =
						res.data.response.form_transaction_id || 0
					payload.activity_inline_data = ''
					payload.activity_timeline_collection =
						params.activity_timeline_collection
					const timelineRes = await addTimelineUpdate(payload)
					return {
						success: true,
						activity_id: payload.activity_id,
					}
				} else {
					console.log('Error Occurred!', res)
					return { isError: true, message: 'error occurred!' }
				}
			}
		} catch (error) {
			console.log('Activity add ', error)
			return error
		}
	})
}

export function useSubmitForm() {
	return useMutation(async params => {
		try {
			const payload = {
				...params,
				...getLocalAuthDetails(),
				activity_type_category_id: 9,
				activity_sub_type_id: 0,
				activity_access_role_id: 21,
				asset_message_counter: 0,
				activity_status_type_category_id: 1,
				activity_status_type_id: 22,
				asset_participant_access_id: 21,
				activity_flag_file_enabled: 1,
				activity_parent_id: 0,
				flag_pin: 0,
				flag_offline: 0,
				flag_retry: 0,
				message_unique_id: getMessageUniqueId(),
				track_gps_datetime: getCurrentDateUTC(),
				track_latitude: '0.0',
				track_longitude: '0.0',
				track_altitude: 0,
				track_gps_accuracy: '0',
				track_gps_status: 0,
				service_version: 1,
				app_version: 1,
				api_version: 1,
				device_os_id: 5,
				activity_stream_type_id: 705,
				form_transaction_id: 0,
				activity_timeline_text: '',
				activity_timeline_url: '',
				flag_timeline_entry: 1,
				file_activity_id: 0,
				is_mytony: 1,
				// is_refill: 0,
				expression: '',
				activity_timeline_collection: '',
				activity_participant_collection: '',
			}
			let res = await addActivity(payload)
			if (res.status === 200) {
				let workflowParams = Object.assign({}, payload)
				workflowParams.activity_id = res.data.response.activity_id
				workflowParams.activity_type_category_id =
					workflowParams.activity_type_category_id || 48
				workflowParams.form_transaction_id =
					res.data.response.form_transaction_id || 0
				if (!!res.data.response.form_transaction_id) {
					if (!!params.isOrigin) {
						payload.activity_id = res.data.response.activity_id
						const finalRes = await addActivity(workflowParams)
						payload.workflow_activity_id = finalRes.data.response.activity_id
						payload.activity_id = finalRes.data.response.activity_id
					} else {
						payload.activity_id = payload.activity_id
						payload.activity_participant_collection =
							params.activity_participant_collection
						const pRes = await inviteParticipant(payload)
						// console.log('participant add', pRes);
					}
					payload.data_activity_id = res.data.response.activity_id // payload.activity_id
					payload.activity_type_category_id = 48
					payload.form_transaction_id =
						res.data.response.form_transaction_id || 0
					payload.message_unique_id = getMessageUniqueId()
					payload.activity_inline_data = ''
					payload.activity_timeline_collection =
						params.activity_timeline_collection
					const timelineRes = await addTimelineUpdate(payload)
					console.log('Error on timelineRes', timelineRes)
					return {
						success: true,
						activity_id: payload.activity_id,
						form_transaction_id: payload.form_transaction_id,
					}
				} else {
					console.log('Error Occurred!', res)
					return { isError: true, message: 'error occurred!' }
				}
			}
		} catch (error) {
			console.log('Activity add ', error)
			return error
		}
	})
}

// Load statusList of an activity where user is paraticipating in.
export function useLoadStatusAndActivityType() {
	return useMutation(async params => {
		try {
			let payload = {
				...params,
				...getLocalAuthDetails(),
				is_active: 1,
				is_due: 0,
				is_unread: 0,
				limit_value: 500,
				start_from: 0,
			}
			let res = await LoadStatusAndActivityType(payload)
			if (res.data.response && res.data.response.length > 0) {
				return res.data.response
			} else {
				return []
			}
		} catch (error) {
			console.log('Load activity listing error', error)
			return error
		}
	})
}

export function useLoadActivityTypeList() {
	return useMutation(async ({ catId }) => {
		try {
			let payload = {
				...getLocalAuthDetails(),
				log_datetime: '1970-01-01 00:00:00',
				start_from: 0,
				limit_value: 500,
			}
			let res = await loadActivityTypeList(payload)
			if (res.data.status === 200) {
				if (!!catId) {
					return res.data.response.find(
						i => i.activity_type_category_id === catId
					)
				} else {
					return res.data.response
				}
			} else {
				return []
			}
		} catch (err) {
			console.log('Error on activity types fetch', err)
			return err
		}
	})
}

export function useLoadActivityStatusList() {
	return useMutation(async ({ catId }) => {
		try {
			let payload = {
				...getLocalAuthDetails(),
				log_datetime: '1970-01-01 00:00:00',
				start_from: 0,
				limit_value: 500,
			}
			let res = await loadActivityStatusList(payload)
			if (res.data.status === 200) {
				if (!!catId) {
					return res.data.response.find(
						i =>
							i.activity_type_category_id === catId &&
							i.activity_status_type_id === 22
					)
				} else {
					return res.data.response
				}
			} else {
				return []
			}
		} catch (err) {
			console.log('Error on activity status fetch', err)
			return err
		}
	})
}

export function useExternalGuestsAdd() {
	return useMutation(async params => {
		try {
			let payload = {
				...params,
				...getLocalAuthDetails(),
			}
			let res = await addGuests(payload)
			if (res.data.response) {
				return res.data.response
			} else {
				return []
			}
		} catch (error) {
			console.log('add guests error', error)
			return error
		}
	})
}

export function useLoadWorkforceLevelForms() {
	return useMutation(async params => {
		try {
			let payload = {
				...getLocalAuthDetails(),
				page_start: 0,
				page_limit: 100,
			}
			let res = await loadWorkforceLevelForms(payload)
			if (res.status === 200) {
				return {
					list: res.data.response,
				}
			}
		} catch (error) {
			return error
		}
	})
}

export function useLoadEDCFieldId() {
	return useMutation(async ({ activity, activityDate }) => {
		try {
			let payload = {
				...getLocalAuthDetails(),
				activity_id: activity.activity_id,
				activity_cover_data: JSON.stringify({
					title: {
						old: activity.activity_title,
						new: activity.activity_title,
					},
					description: { old: '', new: '' },
					duedate: {
						old: activity.activity_datetime_end_deferred,
						new: getCurrentDateUTCSTART(activityDate),
					},
				}),
				activity_type_id: activity.activity_type_id,
			}
			let res = await loadEDCFieldId(payload)
			const { activity_cover_data } = payload
			const { duedate } = !!activity_cover_data
				? JSON.parse(activity_cover_data)
				: {}
			if (res.data.response.length > 0) {
				const edcFieldData = {
					data: res.data.response,
					duedate,
				}
				return edcFieldData
			}
		} catch (error) {
			console.log('error fetching edc field', error)
			return error
		}
	})
}

export function useAlterFileCover() {
	return useMutation(async ({ activity, activity_cover_data }) => {
		try {
			const { asset_id } = getLocalAuthDetails()
			let payload = {
				...getLocalAuthDetails(),
				activity_id: activity.activity_id,
				activity_cover_data,
				activity_type_category_id: 48,
				activity_type_id: activity.activity_type_id,
				activity_access_role_id: 26,
				activity_parent_id: 0,
				flag_pin: 0,
				flag_priority: 0,
				flag_offline: 0,
				flag_retry: 0,
				asset_message_counter: 0,
				message_unique_id: getMessageUniqueId(asset_id),
				track_latitude: 0,
				track_longitude: 0,
				track_altitude: 0,
				track_gps_datetime: getCurrentDateUTC(),
				track_gps_accuracy: 0,
				track_gps_status: 0,
				service_version: 1.0,
			}
			let res = await alterFileCover(payload)
			return res
		} catch (error) {
			console.log('error fetching AlterFile field', error)
			return error
		}
	})
}

export function useLoadChildOrderActivity() {
	return useMutation(async (params = {}) => {
		try {
			let payload = {
				...params,
				...getLocalAuthDetails(),
				start_from: params.page_start,
				limit_value: params.page_limit,
				service_version: 1.0,
				app_version: 1.0,
				device_os_id: 5,
				based_on_asset_access: 1,
			}
			let res = await loadChildOrderActivityList(payload)
			return handleResponse(res, {
				page_start: payload.page_start,
				page_limit: payload.page_limit,
			})
		} catch (error) {
			return error
		}
	})
}

export function useExportTimeLineData() {
	return useMutation(async (params = {}) => {
		try {
			let payload = {
				...params,
			}
			let res = await exportTimeLine(payload)
			return res
		} catch (error) {
			return error
		}
	})
}
export function useCloneActivty() {
	return useMutation(
		async ({
			activity,
			authData,
			cloneActivityName,
			workflowForm,
			activityType,
			activityStatus,
		}) => {
			try {
				const {
					activity_id,
					activity_type_id,
					activity_lead_asset_id,
					activity_lead_asset_type_id,
					activity_lead_asset_first_name,
				} = activity
				const { asset_id, account_id, workforce_id } = authData
				const formData = await buildFormData({
					authData,
					workflowForm,
					activityType,
					activityStatus,
				})

				let payload = {
					...getLocalAuthDetails(),
					flag: 1,
					form_id: 0,
					activity_id,
					activity_type_id,
					user_account_id: account_id,
					user_workforce_id: workforce_id,
					access_level_id: 0,
					track_latitude: 0,
					track_longitude: 0,
					track_gps_accuracy: 0,
					track_gps_status: 0,
					track_gps_location: 0,
					message_unique_id: getMessageUniqueId(asset_id),
					log_datetime: '1970-01-01 00:00:00',
					track_gps_datetime: getCurrentDateUTC(),
					is_refill: 0,
					activity_participant_collection: JSON.stringify(
						buildParticipantParams(authData)
					),
					activity_title: cloneActivityName,
					activity_sub_type_id: 0,
					formData,
				}
				const parmswitholdData = payload
				let res = await loadSubmittedFormList(payload)
				if (res.data.response.length > 0) {
					const formsDetails = res.data.response[res.data.response.length - 1]
					let activity_inline_data = JSON.parse(formsDetails.data_entity_inline)
					formsDetails.data_entity_inline = activity_inline_data
					formsDetails.formData = payload.formData
					const addFormparams = {
						account_id: payload.account_id,
						activity_access_role_id: 21,
						activity_datetime_end: getAddToCurrentDateUTC(
							getCurrentDateUTC(),
							5
						),
						activity_datetime_start: getCurrentDateUTC(),
						activity_description: formsDetails.activity_description,
						activity_flag_file_enabled: 1,
						activity_form_id: payload.formData.form_id,
						activity_id: 0,
						activity_inline_data: JSON.stringify(
							activity_inline_data.form_submitted
						),
						activity_parent_id: formsDetails.parent_activity_id,
						// activity_participant_collection:
						//   parmswitholdData.activity_participant_collection,
						activity_status_id:
							payload.formData.statusType[0].activity_status_id,
						activity_status_type_category_id:
							formsDetails.activity_status_type_category_id,
						activity_status_type_id: formsDetails.activity_status_type_id || 22,
						activity_stream_type_id: 705,
						activity_sub_type_id: parmswitholdData.activity_sub_type_id || 0,
						// activity_timeline_collection: JSON.stringify(activity_inline_data),
						activity_timeline_text: '',
						activity_timeline_url: '',
						activity_title: parmswitholdData.activity_title,
						activity_title_expression: null,
						activity_type_category_id:
							payload.formData.statusType[0].activity_type_category_id,
						activity_type_id: payload.formData.activityType[0].activity_type_id,

						api_version: 1.0,
						app_version: 1.0,
						asset_id: parmswitholdData.asset_id,
						asset_message_counter: 0,
						asset_participant_access_id: 21,
						asset_token_auth: parmswitholdData.asset_token_auth,
						auth_asset_id: parmswitholdData.asset_id,
						// data_entity_inline: JSON.stringify(
						//   activity_inline_data.form_submitted
						// ),
						device_os_id: 5,
						expression: '',
						file_activity_id: 0,
						flag_offline: 0,
						flag_pin: 0,
						flag_retry: 0,
						flag_timeline_entry: 1,
						form_id: payload.formData.form_id,
						form_transaction_id: 0,
						generated_account_code: null,
						generated_group_account_name: null,
						gst_number: null,
						is_mytony: 1,
						is_refill: parmswitholdData.is_refill,
						message_unique_id: parmswitholdData.message_unique_id,
						organization_id: parmswitholdData.organization_id,
						pan_number: '',
						service_version: 1,
						track_altitude: 0,
						track_gps_accuracy: '0',
						track_gps_datetime: parmswitholdData.track_gps_datetime,
						track_gps_status: 0,
						track_latitude: '0.0',
						track_longitude: '0.0',
						workflow_activity_id: 0,
						workforce_id: formsDetails.workforce_id,
						is_cloned: 1,
						lead_asset_first_name: activity_lead_asset_first_name,
						lead_asset_type_id: activity_lead_asset_type_id,
						lead_asset_id: activity_lead_asset_id,
						web_version: '1.0.1',
					}
					let oldParams = Object.assign({}, addFormparams)
					let addRes = await addActivity(addFormparams)
					if (addRes.status === 200) {
						addFormparams.activity_id = addRes.data.response.activity_id
						addFormparams.form_transaction_id =
							addRes.data.response.form_transaction_id
						addFormparams.message_unique_id = getMessageUniqueId(
							oldParams.asset_id
						)
						addFormparams.activity_type_category_id =
							formsDetails.formData.form_workflow_activity_type_category_id ||
							48
						addFormparams.activity_type_id =
							formsDetails.formData.form_workflow_activity_type_id ||
							formsDetails.formData.statusType[0].activity_type_id
						let addWorkflowRes = await addActivity(addFormparams)
						addFormparams.data_activity_id = addFormparams.activity_id
						let oldActivityId = addWorkflowRes.data.response.activity_id
						addFormparams.workflow_activity_id =
							addWorkflowRes.data.response.activity_id
						addFormparams.message_unique_id = getMessageUniqueId(
							oldParams.asset_id
						)
						addFormparams.activity_participant_collection =
							parmswitholdData.activity_participant_collection
						if (oldActivityId) {
							addFormparams.activity_id = oldActivityId
						}
						addFormparams.activity_type_category_id = 48
						addFormparams.activity_timeline_collection =
							JSON.stringify(activity_inline_data)

						let timelineRes = await addTimelineUpdate(addFormparams)
						if (timelineRes.data.status === 200) {
							return timelineRes.data.response
						} else {
							throw new Error('Cannot update timeline')
						}
					} else {
						throw new Error('Some Thing Went wrong')
					}
				} else {
					throw new Error('No Forms Found')
				}
			} catch (error) {
				return error
			}
		}
	)
}

export function useSubmitDraftForm() {
	return useMutation(async (params = {}) => {
		try {
			let payload = {
				...params,
				...getLocalAuthDetails(),
			}
			let res = await submitAsDraft(payload)
			return res
		} catch (error) {
			return error
		}
	})
}

export function useAlterDraftForm() {
	return useMutation(async (params = {}) => {
		try {
			let payload = {
				...params,
				...getLocalAuthDetails(),
			}
			let res = await alterDraft(payload)
			return res
		} catch (error) {
			return error
		}
	})
}

export function useArchiveDraftForm() {
	return useMutation(async (params = {}) => {
		try {
			let payload = {
				...params,
				...getLocalAuthDetails(),
			}
			let res = await archiveDraft(payload)
			return res
		} catch (error) {
			return error
		}
	})
}

export function useActivityBulkSummary() {
	return useMutation(async (params = {}) => {
		try {
			let payload = {
				...params,
				...getLocalAuthDetails(),
			}
			let res = await activityBulkSummary(payload)
			return res
		} catch (error) {
			return error
		}
	})
}

export function useEmailBulkSummary() {
	return useMutation(async (params = {}) => {
		try {
			let payload = {
				...params,
				...getLocalAuthDetails(),
			}
			let res = await emailBulkSummary(payload)
			return res
		} catch (error) {
			return error
		}
	})
}

export function useBulkEditForm() {
	return useMutation(async (params = {}) => {
		try {
			let payload = {
				...params,
				...getLocalAuthDetails(),
			}
			let res = await bulkEditForm(payload)
			return res
		} catch (error) {
			return error
		}
	})
}

export function useMeetingEnable() {
	return useMutation(async (params = {}) => {
		try {
			let payload = {
				...params,
				...getLocalAuthDetails(),
			}
			let res = await meetingEnable(payload)
			return res
		} catch (error) {
			return error
		}
	})
}

export function useChatCountReset() {
	return useMutation(async (params = {}) => {
		try {
			let payload = {
				...params,
				...getLocalAuthDetails(),
			}
			let res = await resetChatCount(payload)
			return res
		} catch (error) {
			return error
		}
	})
}

export function useChatList() {
	return useMutation(async (params = {}) => {
		try {
			let payload = {
				...params,
				...getLocalAuthDetails(),
			}
			let res = await chatMsgList(payload)
			return res.data.response.data
		} catch (error) {
			return error
		}
	})
}

export function useAddNewMsg() {
	return useMutation(async (params = {}) => {
		try {
			let payload = {
				...params,
				...getLocalAuthDetails(),
			}
			let res = await addNewMsg(payload)
			return res
		} catch (error) {
			return error
		}
	})
}

export function useAddNewChatActivity() {
	return useMutation(async (params = {}) => {
		try {
			let payload = {
				...params,
				...getLocalAuthDetails(),
			}
			let res = await addActivity(payload)
			return res
		} catch (error) {
			return error
		}
	})
}

export function useInviteChatParticipant() {
	return useMutation(async (params = {}) => {
		try {
			let payload = {
				...params,
				...getLocalAuthDetails(),
			}
			let res = await inviteChatParticipant(payload)
			return res
		} catch (error) {
			return error
		}
	})
}

export function useLoadGroupParticipants() {
	return useMutation(async (params = {}) => {
		try {
			let payload = {
				...params,
				...getLocalAuthDetails(),
			}
			let res = await loadActivityParticipant(payload)
			return res.data.response.data
		} catch (error) {
			return error
		}
	})
}

export function useLoadOrgConfigList() {
	return useMutation(async (params = {}) => {
		try {
			let payload = {
				organization_id: getLocalAuthDetails().organization_id,
			}
			let res = await getOrgConfigList(payload)
			return res.data.response
		} catch (error) {
			return error
		}
	})
}

export function useLoadAccessSelectActivity() {
	return useMutation(async (params = {}) => {
		try {
			let payload = {
				...params,
				...getLocalAuthDetails(),
			}
			let res = await getAccessSelectActivity(payload)
			return res.data.response
		} catch (error) {
			return error
		}
	})
}

export function useAddParentActivity() {
	return useMutation(async (params = {}) => {
		try {
			if (!!params) {
				const { activity_id, parent_activity_id } = params
				let userData = getLocalAuthDetails()
				let payload = {
					...userData,
					log_datetime: '1970-01-01 00:00:00',
					activity_id: activity_id,
					parent_activity_id: parent_activity_id,
				}
				let res = await addParentActivity(payload)
				return res.data.response
			}
		} catch (error) {
			return error
		}
	})
}

export function useCheckAccountTitleDup() {
	return useMutation(async params => {
		try {
			let userData = getLocalAuthDetails()
			let payload = {
				...userData,
				...params,
			}
			let res = await accountTitleDupCheck(payload)
			return res.data
		} catch (error) {
			return error
		}
	})
}

export function useLoadGanntChildActivity() {
	return useMutation(async (params = {}) => {
		try {
			let payload = {
				...params,
				...getLocalAuthDetails(),
				start_from: params.page_start,
				limit_value: params.page_limit,
				service_version: 1.0,
				app_version: 1.0,
				device_os_id: 5,
			}
			let res = await getGanntChildActivity(payload)
			return res
		} catch (error) {
			return error
		}
	})
}

export function useSetActivityRef() {
	return useMutation(async (params = {}) => {
		try {
			let payload = {
				...params,
				...getLocalAuthDetails(),
			}
			let res = await setActivityReference(payload)
			return res
		} catch (error) {
			return error
		}
	})
}

export function useUpdateActivityRef() {
	return useMutation(async (params = {}) => {
		try {
			let payload = {
				...params,
				...getLocalAuthDetails(),
			}
			let res = await updateActivityReference(payload)
			return res
		} catch (error) {
			return error
		}
	})
}

export function useDeleteActivityRef() {
	return useMutation(async (params = {}) => {
		try {
			let payload = {
				...params,
				...getLocalAuthDetails(),
			}
			let res = await deleteActivityReference(payload)
			return res
		} catch (error) {
			return error
		}
	})
}

export function useUpdateActivityDueDates() {
	return useMutation(async (params = {}) => {
		try {
			let payload = {
				...params,
				...getLocalAuthDetails(),
			}
			let res = await updateActivityDueDates(payload)
			return res
		} catch (error) {
			return error
		}
	})
}

export function useUpdateActivityProgress() {
	return useMutation(async (params = {}) => {
		try {
			let payload = {
				...params,
				...getLocalAuthDetails(),
			}
			let res = await updateActivityProgress(payload)
			return res
		} catch (error) {
			return error
		}
	})
}

export const useLoadResourcesBuildingList = () => {
	return useMutation(async (params = {}) => {
		try {
			if (!!params) {
				const { lead_asset_type_id, manager_asset_id, lead_asset_id } = params
				const requestData = {
					...getLocalAuthDetails(),
					asset_type_id: lead_asset_type_id,
					manager_asset_id,
					current_lead_asset_id: lead_asset_id,
					flag: 5,
				}
				let res = await fetchManagerResources(requestData)
				return res.data ? res.data.response : []
			}
		} catch (error) {
			console.log('Error', error)
			return error
		}
	})
}

export const useLoadPreviousStatusList = () => {
	return useMutation(async (params = {}) => {
		try {
			if (!!params) {
				const { activity_id, activity_status_id, page_start = 0 } = params
				const requestData = {
					...getLocalAuthDetails(),
					current_activity_status_id: activity_status_id,
					activity_id,
					page_start,
					page_limit: 50,
				}
				let res = await loadPreviousStatusList(requestData)
				return res.data ? res.data.response : []
			}
		} catch (error) {
			console.log('Error', error)
			return error
		}
	})
}
export const useAlterActivityStatus = () => {
	return useMutation(async (params = {}) => {
		try {
			if (!!params) {
				const { activity_id, activity_status_id, activity_status_type_id } =
					params
				const requestData = {
					...getLocalAuthDetails(),
					activity_id,
					activity_access_role_id: 0,
					activity_status_id,
					activity_status_type_id,
					activity_status_type_category_id: 0,
					activity_type_id: 0,
					activity_type_category_id: 48,
					is_status_rollback: 1,
				}
				let res = await alterActivityStatus(requestData)
				return res.data ? res.data.response : []
			}
		} catch (error) {
			console.log('Error', error)
			return error
		}
	})
}

export const useLoadSelectCategory = () => {
	return useMutation(async (params = {}) => {
		try {
			if (!!params) {
				const requestData = {
					...getLocalAuthDetails(),
					...params,
				}
				let res = await loadSelectCategory(requestData)
				return res.data ? res.data.response : []
			}
		} catch (error) {
			console.log('Error', error)
			return error
		}
	})
}
export const useReferredActivity = () => {
	return useMutation(async (params = {}) => {
		try {
			if (!!params) {
				const requestData = {
					...getLocalAuthDetails(),
					...params,
				}
				let res = await loadReferredActivity(requestData)
				return res.data ? res.data.response : []
			}
		} catch (error) {
			console.log('Error', error)
			return error
		}
	})
}

export const useCheckActivityManager = () => {
	return useMutation(async (params = {}) => {
		try {
			if (!!params) {
				const requestData = {
					...getLocalAuthDetails(),
					...params,
				}
				let res = await checkActivityManager(requestData)
				return res.data ? res.data.response : []
			}
		} catch (error) {
			console.log('Error', error)
			return error
		}
	})
}

export const useSetPriority = () => {
	return useMutation(async (params = {}) => {
		try {
			if (!!params) {
				const requestData = {
					...getLocalAuthDetails(),
					...params,
				}
				let res = await setPriority(requestData)
				return res.data ? res.data.response : []
			}
		} catch (error) {
			console.log('Error', error)
			return error
		}
	})
}

export const useUpdateActivityMasterData = () => {
	return useMutation(async (params = {}) => {
		try {
			if (!!params) {
				const requestData = {
					...getLocalAuthDetails(),
					...params,
				}
				let res = await updateActivityMasterData(requestData)
				return res.data ? res.data.response : []
			}
		} catch (error) {
			console.log('Error', error)
			return error
		}
	})
}

export const useMuteAssetNotification = () => {
	return useMutation(async (params = {}) => {
		try {
			if (!!params) {
				const requestData = {
					...getLocalAuthDetails(),
					...params,
				}
				let res = await notificationAsset(requestData)
				return res.data ? res.data.response : []
			}
		} catch (error) {
			console.log('Error', error)
			return error
		}
	})
}

export const useUpdateCancelRequest = () => {
	return useMutation(async (params = {}) => {
		try {
			if (!!params) {
				const requestData = {
					...getLocalAuthDetails(),
					...params,
				}
				let res = await updateCancelRequest(requestData)
				return res.data ? res.data.response : []
			}
		} catch (error) {
			console.log('Error', error)
			return error
		}
	})
}

export const useDeclineHistory = () => {
	return useMutation(async (params = {}) => {
		try {
			if (!!params) {
				const requestData = {
					...getLocalAuthDetails(),
					...params,
				}
				let res = await declineHistory(requestData)
				return res.data ? res.data.response : []
			}
		} catch (error) {
			console.log('Error', error)
			return error
		}
	})
}

export function useRemoveParticipantAsLead() {
	return useMutation(async ({ lead_asset_id, ...rest }) => {
		try {
			let payload = {
				...getLocalAuthDetails(),
				lead_asset_id,
				...rest,
				app_version: 1,
				service_version: 1,
				device_os_id: 5,
			}
			return await removeParticipantAsLead(payload)
		} catch (error) {
			return error
		}
	})
}

export function useLoadWorkflowParticipant() {
	return useMutation(async params => {
		try {
			let payload = {
				...getLocalAuthDetails(),
				...params,
				app_version: 1,
				service_version: 1,
				device_os_id: 5,
				page_limit: 100, /// TODO: Handle recursive calls if needed.
				page_start: 0,
				datetime_differential: '1970-01-01 00:00:00',
			}
			let res = await loadWorkflowParticipant(payload)
			return res?.data?.response || []
		} catch (error) {
			return error
		}
	})
}

export function useTimilineFilterOptions() {
	return useMutation(async params => {
		try {
			let payload = {
				...params,
			}
			let res = await timelineFilterOptions(payload)
			return res?.data?.response || []
		} catch (error) {
			return error
		}
	})
}
export function useEditFeilds() {
	return useMutation(async params => {
		try {
			let payload = {
				...getLocalAuthDetails(),
				...params,
			}
			let res = await editfieldConfigs(payload)
			return res?.data?.response || []
		} catch (error) {
			return error
		}
	})
}
