/**
 * Helper function to call MS Graph API endpoint
 * using the authorization bearer token scheme
 */

import { loadAwsFile } from './utils/aws.util'

export const callMSGraph = (endpoint, token, callback) => {
	const headers = new Headers()
	const bearer = `Bearer ${token}`

	headers.append('Authorization', bearer)

	const options = {
		method: 'GET',
		headers: headers,
	}

	console.log('request made to Graph API at: ' + new Date().toString())

	fetch(endpoint, options)
		.then(response => response.json())
		.then(response => callback(response, endpoint))
		.catch(error => console.log(error))
}

export const createCalendarEventGraph = accessToken => {
	const headers = new Headers()
	headers.append('Authorization', `Bearer ${accessToken}`)
	headers.append('Content-Type', 'application/json')

	console.log(accessToken)
	const eventData = {
		subject: 'Calendar Event Test',
		start: {
			dateTime: '2023-03-27T17:00:00',
			timeZone: 'Asia/Kolkata',
		},
		end: {
			dateTime: '2023-03-27T18:00:00',
			timeZone: 'Asia/Kolkata',
		},
		attendees: [
			//   {
			//     emailAddress: {
			//       address: "akshay@grenerobotics.com",
			//       name: "Akshay Singh R",
			//     },
			//     type: "required",
			//   },
			//   {
			//     emailAddress: {
			//       address: "jaya@grenerobotics.com",
			//       name: "Jaya",
			//     },
			//     type: "required",
			//   },
			{
				emailAddress: {
					address: 'vasudevan@pronteff.com',
					name: 'Vasu',
				},
				type: 'required',
			},
		],
		isOnlineMeeting: true,
		// "onlineMeetingProvider": "teamsForBusiness"
	}

	fetch('https://graph.microsoft.com/v1.0/me/events', {
		method: 'POST',
		headers: headers,
		body: JSON.stringify(eventData),
	})
		.then(response => {
			console.log('Event created successfully', response)
		})
		.catch(error => {
			console.log(error)
		})
}

export const sendEmailViaGraph = (accessToken, mail) => {
	const headers = new Headers()
	headers.append('Authorization', `Bearer ${accessToken}`)
	headers.append('Content-Type', 'application/json')

	const sendMail = {
		message: {
			subject: mail.subject,
			body: {
				contentType: 'Text',
				content: mail.message,
			},
			toRecipients: mail.to.split(',').map(mt => ({
				emailAddress: {
					address: mt.replace(/ /g, ''),
				},
			})),
			//   ccRecipients: [
			//     {
			//       emailAddress: {
			//         address: "jaya@grenerobotics.com",
			//       },
			//     },
			//   ],
			// attachments: [
			// 	{
			// 		'@odata.type': '#microsoft.graph.fileAttachment',
			// 		name: 'attachment.txt',
			// 		contentType: 'text/plain',
			// 		contentBytes: 'SGVsbG8gV29ybGQh',
			// 	},
			// ],
		},
		saveToSentItems: 'true',
	}
	setTimeout(async () => {
		await fetch('https://graph.microsoft.com/v1.0/me/sendMail', {
			method: 'POST',
			headers: headers,
			body: JSON.stringify(sendMail),
		})
			.then(response => {
				console.log('Mail Sent successfully', response)
				return true
			})
			.catch(error => {
				console.log(error)
				return false
			})
	}, 2000)
}

export const sendEmailAttachmentsViaGraph = async (accessToken, mail) => {
	const headers = new Headers()
	headers.append('Authorization', `Bearer ${accessToken}`)
	headers.append('Content-Type', 'application/json')

	let fileAttach = []
	if (!!mail.attachments) {
		mail.attachments.forEach(async file => {
			if (file.value) {
				await loadAwsFile(file.value, d => {
					fileAttach.push({
						'@odata.type': '#microsoft.graph.fileAttachment',
						name: d.name,
						contentType: d.type,
						contentBytes: d.data,
					})
				})
			}
		})
	}

	const sendMail = {
		message: {
			subject: mail.subject,
			body: {
				contentType: 'HTML',
				content: mail.message,
			},
			toRecipients: mail.to.split(',').map(mt => ({
				emailAddress: {
					address: mt.replace(/ /g, ''),
				},
			})),
			//   ccRecipients: [
			//     {
			//       emailAddress: {
			//         address: "jaya@grenerobotics.com",
			//       },
			//     },
			//   ],
			attachments: fileAttach,
		},
		saveToSentItems: 'true',
	}

	setTimeout(async () => {
		await fetch('https://graph.microsoft.com/v1.0/me/sendMail', {
			method: 'POST',
			headers: headers,
			body: JSON.stringify(sendMail),
		})
			.then(response => {
				console.log('Mail Sent successfully', response)
				return true
			})
			.catch(error => {
				console.log(error)
				return false
			})
	}, 2000)
}
