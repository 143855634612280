import { useMutation } from 'react-query';
import { getLocalAuthDetails } from '../utils/common.util';
import {
  fetchBroadcastMessage,
  updateBroadcastReadStatus,
  fetchBroadcastMessageCount,
} from '../services/broadcast.service';

export function useGetBroadcastMessage() {
  return useMutation(async (params = {}) => {
    let userData = getLocalAuthDetails();
    try {
      let payload = {
        ...userData,
        ...params,
        start_from: 0,
        limit_value: 100,
      };
      let res = await fetchBroadcastMessage(payload);
      return res.data.response;
    } catch (error) {
      return error;
    }
  });
}

export function useBroadcastMessagecount() {
  return useMutation(async (params = {}) => {
    let userData = getLocalAuthDetails();
    try {
      let payload = {
        organization_id: userData.organization_id,
        asset_id: userData.asset_id,
        asset_token_auth: userData.asset_token_auth,
      };
      let res = await fetchBroadcastMessageCount(payload);
      return res;
    } catch (error) {
      return error;
    }
  });
}

export function useUpdateReadStatus() {
  return useMutation(async (params = {}) => {
    let userData = getLocalAuthDetails();
    try {
      let payload = {
        ...params,
        organization_id: userData.organization_id,
        asset_id: userData.asset_id,
        asset_token_auth: userData.asset_token_auth,
        page_start: 0,
        page_limit: 100,
      };
      let res = await updateBroadcastReadStatus(payload);
      return res;
    } catch (error) {
      return error;
    }
  });
}
